import axios from "axios";

const API = "https://uat-services.excesswies.com/api";
const WIES_API = axios.create({
  baseURL: API,
});
const buildConfig = (accessToken) => {
  const authString = "Bearer ".concat(accessToken);
  const config = {
    headers: { Authorization: authString },
  };
  return config;
};

// Login
export const Login = (values) => {
  return WIES_API.post(`/candidate/login`, values);
};

// Login
export const request_for_otp = (values) => {
  return WIES_API.post(`/candidate/request_for_otp`, values);
};

// resent otp
export const request_for_resent_otp = (values) => {
  return WIES_API.post(`/candidate/request_for_resent_otp`, values);
};

// resent otp
export const login_with_otp = (values) => {
  return WIES_API.post(`/candidate/login_with_otp`, values);
};

// Change Password
export const ChangePasswordaction = (values, token) => {
  return WIES_API.post(
    `/v1/candidate/candidate_password_update`,
    values,
    buildConfig(token)
  );
};

// Reset Password
export const PasswordReset = (values, token) => {
  return WIES_API.post(
    `/v1/candidate/candidate_initial_password_update`,
    values,
    buildConfig(token)
  );
};

// Logout
export const Logout = (token) => {
  return WIES_API.post(`/v1/candidate/logout`, "", buildConfig(token));
};

// GET onboarding
export const getOnboarding = (id, token) => {
  return WIES_API.get(`/v1/candidate/onboardingv2/${id}`, buildConfig(token));
};

// GET States
export const getStates = (token) => {
  return WIES_API.get(`/v1/reference_state/short_us_states`, buildConfig(token));
};

// GET Questions
export const getQuestions = (token) => {
  return WIES_API.get(`/v1/get_security_questions`, buildConfig(token));
};


// POST onboarding
export const updateOnboarding = (id, values, token) => {
  return WIES_API.post(
    `/v1/candidate/onboardingv2/${id}`,
    values,
    buildConfig(token)
  );
};

// POST onboarding
export const updateradid = (id,values, token) => {
  return WIES_API.post(
    `/v1/candidate/rad_id_mapping`,
    values,
    buildConfig(token)
  );
};

// POST onboarding
export const update_profile = (id,values, token) => {
  return WIES_API.post(
    `/v1/candidate/update_profile/${id}`,
    values,
    buildConfig(token)
  );
};

// POST onboarding
export const updatefailradid = (values, token) => {
  return WIES_API.post(
    `/v1/candidate/update_documents`,
    values,
    buildConfig(token)
  );
};
